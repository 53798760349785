<template>
  <section class="h-layout">
    <slot/>
  </section>
</template>
<script>
export default {
  name: 'HLayout'
}
</script>
<style lang="scss" scoped>
.h-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #F6F7FA;
  // background: url('@/assets/image/bg.png');
  padding: 0px 16px 16px 16px;
  min-height: calc(100vh - 48px);
  justify-content: center;

  &--hide-logo {
    min-height: 100vh;
  }

  > div {
    // max 1196px && 6-col => 1196/2
    max-width: 598px;
  }

  @include tablet {

    > div {
      max-width: unset;
    }
  }
}
</style>
