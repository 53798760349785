import { CrossStorageClient } from 'cross-storage'

const storage = new CrossStorageClient(location.origin + '/hub', { timeout: 10000, })

export const getStorage = (key) => {
  return new Promise((resolve) => {
    storage.onConnect().then(() => {
      return storage.get(key)
    }).then((res) => {
      resolve(JSON.parse(res))
    })['catch']((err) => {
      console.log('getStorage error: ', { err })
      // reject(err)
    })
  })
}

export const setStorage = (key, value) => {
  return new Promise((resolve) => {
    storage.onConnect().then(() => {
      return storage.set(key, value)
    }).then((res) => {
      resolve(res)
    })['catch']((err) => {
      console.log('setStorage error: ', { err })
      // reject(err)
    })
  })
}

export const delStorage = (key) => {
  return new Promise((resolve) => {
    storage.onConnect().then(() => {
      return storage.del(key)
    }).then((res) => {
      resolve(res)
    })['catch']((err) => {
      console.log('delStorage error: ', { err })
      // reject(err)
    })
  })
}
