import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import i18n from '../i18n'
import VueCookies from 'vue-cookies'
import { handleRedirectReferer } from '@/utils'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'homePage',
    component: () => import('../views/index.vue'),
    beforeEnter: async (to, from, next) => {
      // fanso退出登录清token
      const fansoLogoutType = to?.query?.logoutType || ''
      if (fansoLogoutType === 'fan')
        await store.dispatch('logout')
      else if (fansoLogoutType === 'creator')
        await store.dispatch('removeFansoAuth')
      // check if token exists then go to account page
      if (store.state.token) next({ name: 'accountPage', query: from.query, replace: true })
      // check if fanso creator token exists then go to fanso creator page
      if (VueCookies.get('fanso-creator-token')) next({ name: 'fansoCreatorPage', query: from.query, replace: true })
      else next()
    },
  },
  {
    path: '/creator',
    name: 'fansoCreatorPage',
    component: () => import('../views/fanso/index.vue'),
    beforeEnter: async (to, from, next) => {
      const fansoCreatorToken = VueCookies.get('fanso-creator-token')
      console.log('query', to?.query, fansoCreatorToken)
      if (!fansoCreatorToken) next({ name: 'homePage', query: from.query, replace: true })
      else {
        if (to?.query?.referer) {
          // 存在referer url和fanso token，直接跳转
          return handleRedirectReferer(to?.query?.referer, fansoCreatorToken, true, 'cToken')
        }
        else next()
      }

    },
  },
  {
    path: '/user/change-email-reset',
    redirect: { name: 'changeEmailResetPage' }
  },
  {
    path: '/account/change-email',
    name: 'changeEmailPage',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/changeEmail.vue')
  },
  {
    path: '/account/change-email-reset',
    name: 'changeEmailResetPage',
    component: () => import('../views/changeEmailReset.vue')
  },
  {
    path: '/account',
    name: 'accountPage',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/account.vue')
  },
  {
    path: '/my-games',
    name: 'myGames',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/games.vue')
  },
  {
    path: '/hub',
    name: 'hubPage',
    component: () => import('../views/hub.vue')
  },
  {
    path: '/verify-email',
    name: 'verifyEmail',
    component: () => import('../views/verifyEmail.vue')
  },
  {
    path: '/user/verifyEmail',
    name: 'userVerifyEmail',
    component: () => import('../views/userVerifyEmail.vue')
  },
  {
    path: '/transaction-list',
    name: 'transactionList',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/transactionList.vue')
  },
  {
    path: '/transaction-detail',
    name: 'transactionDetail',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/transactionDetail.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => {
    return { x: 0, y: 0 } // 始终滚动到顶部
  }
})

router.beforeEach(async (to, from, next) => {
  // set token
  const tokenInCookie = VueCookies.get('h365') // 用cookie做持久化
  const tokenInQuery = to?.query?.token // query token 優先權更大
  const token = tokenInQuery ?? tokenInCookie
  if (token)
    store.commit('setToken', token) // 保存在vuex和cookie
  if (tokenInQuery) {
    // 刪除 query token 並重新跳轉
    delete to.query.token
    next({...to })
  }

  // set language
  const langInCookie = VueCookies.get('h365-lang') // 用cookie做持久化
  const langInQuery = to?.query?.language // query token 優先權更大
  const lang = langInQuery ?? langInCookie
  if (lang && i18n.availableLocales.find(item => item === lang)) {
    i18n.locale = lang
    VueCookies.set('h365-lang', lang)  // 保存到cookie
  }

  // set referer
  if (to?.query?.referer) store.commit('setReferer', to.query.referer)

  if (to?.query?.queryToken) store.commit('setQueryToken', to.query.queryToken)

  // set utm
  if (to?.query?.utm_medium) store.commit('setUtmMedium', to.query.utm_medium)

  if (to?.query?.fromFanso) store.commit('setFromFanso', true)

  if (to?.query?.authCallback) store.commit('setAuthCallback', to.query.authCallback)

  const requireAuth = to.matched.some(record => record.meta.requiresAuth)
  const isAuthenticated = !!(store.state.token)

  if (requireAuth && !isAuthenticated) next({ name: 'homePage' })
  else next()
})

export default router
