import axios from 'axios'
import store from '@/store'
import router from '@/router'
import i18n from '@/i18n'
import HModal from '@/plugin/global-modal'
import VueCookies from 'vue-cookies'

const h365PlatformApi = axios.create({
  baseURL: process.env.VUE_APP_H365_PLATFORM_BASE_URL
})

const fansoApi = axios.create({
  baseURL: process.env.VUE_APP_FANSO_BASE_URL
})

fansoApi.interceptors.request.use(
  (config) => {
    config.headers = {
      ...(VueCookies.get('fanso-creator-token') && { Authorization: VueCookies.get('fanso-creator-token') }),
      ...config.headers,
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

h365PlatformApi.interceptors.request.use(
  (config) => {
    config.headers = {
      ...(store.state.token && { Authorization: `Bearer ${store.state.token}` }),
      ...config.headers,
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

const handleErrorCode = (code, data, message, response) => {
  // 該頁面自己有錯誤頁, 避免重複跳出錯誤訊息
  if (response.config.originUrl === '/user/verifyEmail/verify') return

  const key = `${response.config.method}.${response.config.originUrl ?? response.config.url}.${code}`

  // show error if i18n match
  if (i18n.te(key)) {
    HModal.open('error', {
      content: i18n.t(key),
      errorCode: code
    })
  }
  return
}

/** 变量替换
/* requestUrl 含有变量的字符串
/* paramsObj 变量对象
/* return []
**/
const varsReplace = (requestUrl, paramsObj) => {
  const SYMBOL_REGEXP = /\{{(.+?)\}}/g
  const VARS_ARRAY = requestUrl.match(SYMBOL_REGEXP)
  const newParamsObj = Object.assign({}, paramsObj)
  if (VARS_ARRAY){
    for (const variable of VARS_ARRAY){
      const varChartArray = variable.slice(2, -2)
      delete newParamsObj[varChartArray]
      const value = paramsObj[varChartArray]
      requestUrl = requestUrl.replace(variable, value)
    }
  }
  // 返回新的请求地址、剔除变量后的请求参数
  return [requestUrl, newParamsObj]
}

// 添加发起拦截器
h365PlatformApi.interceptors.request.use(
  config => {
    let param = {}
    config.originUrl = config.url
    param = (config.method === 'get') ? config.params : config.data
    const [requestUrl, newParamsObj] = varsReplace(config.url, param)
    if (config.method === 'get') config.params = newParamsObj
    else config.data = newParamsObj
    config.url = requestUrl
    return config
  },
  error => Promise.reject(error)
)

// Add a response interceptor
h365PlatformApi.interceptors.response.use((response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  const responseData = response?.data ?? {}
  const isSuccess = responseData?.code === 0
  if (!isSuccess) handleErrorCode(responseData.code, responseData?.data ?? {}, responseData.message ?? responseData.msg, response)

  return {
    ...responseData,
    isSuccess: responseData?.code === 0
  }
}, (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  switch (error.response.status) {
  case 401: // token 失效或無效
    store.commit('removeToken')
    store.commit('setUser', null)
    HModal.open('error', {
      content: '认证失效或发生问题，请重新登入',
      onClick: () => router.push({ name: 'homePage' })
    })
    break
  }
  return Promise.reject(error)
})

const cloudStorage = axios.create({
  baseURL: `${process.env.VUE_APP_CLOUD_SOTRAGE_URL}`,
  params: { alt: 'media' }
})

export {
  h365PlatformApi,
  cloudStorage,
  fansoApi
}
