import { getStorage, setStorage, delStorage } from './storage'
function urlRedirect(url) {
  if (!url) return
  const a = document.createElement('a')
  a.href = url
  document.body.appendChild(a)
  a.click()
}

function getDomain() {
  const domain = process.env.NODE_ENV === 'production'
    ? window.location.hostname
    : ''
  return domain
}

function getRootDomain() {
  const domain = process.env.NODE_ENV === 'production'
    ? window.location.hostname.split('.').slice(1).join('.')
    : ''
  return domain
}

function deepCopy(data) {
  return JSON.parse(JSON.stringify(data))
}

function languageMap(lang) {
  switch (lang) {
  case 'zh-CN':
    return 'zh_CN'
  case 'zh-TW':
    return 'zh_TW'
  case 'en-US':
    return 'en_US'
  default:
    return 'zh_CN'
  }
}

function handleRedirectReferer(referer, token, queryToken= true, tokenName = 'token') {
  if (!referer) return
  try {
    const url = new URL(referer)
    if(queryToken) url.searchParams.set(tokenName, token)
    window.location = url.toString()
  }
  catch (e) {
    console.error('handleRedirectReferer exception', e.message)
  }
}

export {
  urlRedirect,
  getDomain,
  getRootDomain,
  deepCopy,
  getStorage,
  setStorage,
  delStorage,
  languageMap,
  handleRedirectReferer
}
