<template>
  <div class="h-girl-modal" @click.stop="close">
    <div class="h-girl-modal-container" @click.stop="">
      <div class="h-girl-modal-logo h-mb-16">
        <img src="@/assets/image/h365-logo.png" alt="h365 logo">
      </div>
      <div class="h-girl-modal-girl h-mb-16">
        <img src="@/assets/image/girl.svg" alt="Hot girl">
      </div>
      <div class="h-modal-content">
        <div v-html="content"></div>
      </div>
      <h-button
        class="h-mt-16"
        shape="round"
        size="large"
        block
        @click="handleClick">
        <span class="text-md">
          {{ buttonText }}
        </span>
      </h-button>
    </div>
  </div>
</template>
<script>
import HButton from '@/components/base/HButton.vue'

export default {
  name: 'HModal',
  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    errorCode: {
      type: Number
    },
    type: {
      type: String,
      default: 'info'
    },
    buttonText: {
      type: String,
      default: '確認'
    },
    onClick: {
      type: Function
    }
  },
  components: { HButton },
  computed: {
    titleIcon() {
      const iconMap = {
        info: 'fa-circle-info',
        warn: 'fa-circle-exclamation',
        error: 'fa-circle-exclamation',
        success: 'fa-circle-check'
      }
      return iconMap?.[this.type] ?? 'fa-circle-info'
    }
  },
  methods: {
    handleClick() {
      if (this.onClick) this.onClick()
      this.close()
    },
    close() {
      this.$destroy()
      this.$el.parentNode.removeChild(this.$el)
    }
  },
}
</script>
<style lang="scss" scoped>
.h-girl-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,.45);
  z-index: 1000;
  &-container {
    width: calc(100% - 16px);
    max-width: 416px;
    background-color: #ffffff;
    border-radius: 16px;
    padding: 24px;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &-content {
    color: color(black-2);
    line-height: 30px;
    letter-spacing: .06em;
    font-size: 14px;
    margin-bottom: 15px;
    a {
      font-weight: bold;
    }
  }
}
</style>