<template>
  <div class="h-loader" :class="{'h-loader--large': large}">
    <span></span>
  </div>
</template>
<script>
export default {
  name: 'HLoader',
  props: {
    large: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.h-loader {
  overflow: hidden;
  width: 16px;
  height: 16px;
}
.h-loader span {
  display: block;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border: 3px solid color(gold-6);
  border-radius: 50%;
  border-top: 3px solid transparent;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

.h-loader--large {
  width: 32px;
  height: 32px;
  span {
    width: 32px;
    height: 32px;
    border: 5px solid color(gold-6);
    border-top: 5px solid transparent;
  }
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>