<template>
  <div class="h-modal" @click.stop="close">
    <div class="h-modal-container" @click.stop="">
      <div class="h-modal-icon">
        <font-icon :icon="`fa-solid ${titleIcon}`" :class="type" />
      </div>
      <div class="h-modal-title">
        <span :class="type">
          {{ i18nTitle  }}
        </span>
      </div>
      <div class="h-modal-content">
        <p>
          {{ content }}
        </p>
        <template v-if="errorCode">
          <p>
            {{ `(Error code: ${errorCode})` }}
          </p>
          <p v-html="tryAgainLater"></p>
        </template>
      </div>      
      <h-button
        type="ghost"
        shape="round"
        size="large"
        block
        @click="handleClick">
        <span class="text-md">
          {{ buttonText }}
        </span>
      </h-button>
    </div>
  </div>
</template>
<script>
import HButton from '@/components/base/HButton.vue'
import i18n from '@/i18n'

export default {
  name: 'HModal',
  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    errorCode: {
      type: Number
    },
    type: {
      type: String,
      default: 'info',
      validator(value) {
        // The value must match one of these strings
        return ['info', 'warn', 'error', 'success'].includes(value)
      }
    },
    buttonText: {
      type: String,
      default: i18n.t('confirm[0]')
    },
    onClick: {
      type: Function
    }
  },
  components: { HButton },
  computed: {
    titleIcon() {
      const iconMap = {
        info: 'fa-circle-info',
        warn: 'fa-circle-exclamation',
        error: 'fa-circle-exclamation',
        success: 'fa-circle-check'
      }
      return iconMap?.[this.type] ?? 'fa-circle-info'
    },
    i18nTitle() {
      return this.title || i18n.t(`modal.title.${this.type}`)
    },
    tryAgainLater() {
      return i18n.t('tryAgainLater', { link: 'https://direct.lc.chat/13877055'})
    }
  },
  methods: {
    handleClick() {
      if (this.onClick) this.onClick()
      this.close()
    },
    close() {
      this.$destroy()
      this.$el.parentNode.removeChild(this.$el)
    }
  }
}
</script>
<style lang="scss" scoped>
.h-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,.45);
  z-index: 1000;
  &-container {
    width: calc(100% - 16px);
    max-width: 416px;
    background-color: #ffffff;
    border-radius: 16px;
    padding: 24px;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &-icon, &-title {
    .info {
      color: color(blue-3);
    }

    .error, .warn {
      color: color(red-3);
    }
    .success {
      color: color(gold-6);
    }
  }
  &-icon {
    color: color(gold-6);
    font-size: 40px;
    margin-bottom: 15px;
  }
  &-title {
    font-size: 24px;
    font-weight: bold;
    color: color(gold-6);
    margin-bottom: 15px;
  }
  &-content {
    color: color(black-2);
    line-height: 30px;
    letter-spacing: .06em;
    font-size: 14px;
    margin-bottom: 15px;
  }
}
</style>
<style lang="scss">
.h-modal-content {
  a {
    color: #262626;
    font-weight: bold;
  }
  a:link {
    text-decoration: none;
  }
}
</style>