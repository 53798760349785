import Vue from 'vue'
import HModal from '@/components/modal'
const ModalConstructor = Vue.extend(HModal)
let instance = null
function open(type, props) {
  instance = new ModalConstructor({ propsData: { type, ...props } })
  instance.vm = instance.$mount()
  document.body.appendChild(instance.vm.$el)
}
function close() {
  if (instance) {
    instance?.vm?.$destroy()
    instance?.vm?.$el?.parentNode?.removeChild(instance.vm.$el)
  }
}

export default {
  open,
  close
}