import { extend } from 'vee-validate'
import { required, email, confirmed } from 'vee-validate/dist/rules'
import i18n from '../i18n'

export default function() {
  extend('required', {
    ...required,
    message: (fieldName) => {
      return i18n.t('validations.required', {
        _field_: fieldName
      })
    }
  })
  // Support email that including special characters to login
  extend('email2', {
    ...email,
    message: (fieldName) => {
      return i18n.t('validations.email', {
        _field_: fieldName
      })
    }
  })
  // Support email that including special characters to login
  extend('password', {
    validate(value) {
      return /^.{6,12}$/.test(value)
    },
    message: (fieldName) => {
      return i18n.t('validations.password', {
        _field_: fieldName
      })
    }
  })

  // 支援fanso creator密码
  extend('password2', {
    validate(value) {
      return /^.{6,20}$/.test(value)
    },
    message: (fieldName) => {
      return i18n.t('validations.password', {
        _field_: fieldName
      })
    }
  })

  extend('confirmed', {
    ...confirmed,
    message: (fieldName) => {
      return i18n.t('validations.confirmed', {
        _field_: fieldName
      })
    }
  })
}
