import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import VueCookies from 'vue-cookies'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import validateExtend from '@/plugin/validation'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import HModal from '@/plugin/global-modal'
import HGirlModal from '@/plugin/girl-modal'
/* solid icons */
import {
  faUser,
  faRightToBracket,
  faRightFromBracket,
  faAngleRight,
  faUserPlus,
  faEye,
  faEyeSlash,
  faWallet,
  faKey,
  faCircleInfo,
  faCircleExclamation,
  faCircleCheck,
  faSyncAlt,
  faHome,
  faGamepad,
  faUserPen,
  faAngleLeft,
  faEllipsis,
  faThumbsUp,
  faFileInvoiceDollar,
  faAngleDown,
  faAngleUp,
  faCopy
} from '@fortawesome/free-solid-svg-icons'

import {
  faBadgeCheck as fasBadgeCheck
} from '@fortawesome/pro-solid-svg-icons'

import {
  faEnvelope,
  faUser as farUser,
  faCircleXmark,
  faCircleCheck as farCircleCheck,
  faMessage
} from '@fortawesome/free-regular-svg-icons'

import {
  faRightToBracket as farRightToBracket,
  faCircleExclamation as farCircleExclamation,
  faBadgeCheck as farBadgeCheck,
  faCircleInfo as farCircleInfo
} from '@fortawesome/pro-regular-svg-icons'

/* add icons to the library */
library.add(
  farUser,
  faUser,
  faRightToBracket,
  faRightFromBracket,
  faAngleRight,
  faUserPlus,
  faEye,
  faEyeSlash,
  faWallet,
  faEnvelope,
  faKey,
  faCircleInfo,
  faCircleExclamation,
  faCircleCheck,
  faSyncAlt,
  faHome,
  faGamepad,
  faUserPen,
  faAngleLeft,
  faEllipsis,
  farRightToBracket,
  farCircleExclamation,
  fasBadgeCheck,
  farBadgeCheck,
  faThumbsUp,
  faCircleXmark,
  farCircleCheck,
  farCircleInfo,
  faFileInvoiceDollar,
  faAngleDown,
  faAngleUp,
  faCopy,
  faMessage
)

// validate rules
validateExtend()

Vue.prototype.$modal = HModal
Vue.prototype.$girlModal = HGirlModal
/* add font awesome icon component */
Vue.component('fontIcon', FontAwesomeIcon)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

Vue.config.productionTip = false

Vue.use(VueCookies)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
