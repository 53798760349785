import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'
import router from '../router'
import { h365PlatformApi, cloudStorage, fansoApi } from '@/plugin/axios'
import { urlRedirect, getDomain, getRootDomain, setStorage, delStorage } from '@/utils'
import i18n from '../i18n'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    clientIp: '',
    user: null,
    referer: '',
    utmMedium: '',
    queryToken: true,
    timeZone: -new Date().getTimezoneOffset() / 60, // 时区
    fromFanso: false,
    authCallback: ''
  },
  getters: {
    queryString() {
      return window.location.search
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token
      setStorage('h365', token)
      VueCookies.set('h365', token , '2y', '/')
    },
    removeToken(state) {
      state.token = ''
      delStorage('h365')
      VueCookies.remove('h365', '/')
    },
    setFansoCreatorToken(state, token) {
      state.fansoCreatorToken = token
      VueCookies.set('fanso-creator-token', token , '1y', '/')
    },
    removeFansoCreatorToken(state) {
      state.fansoCreatorToken = ''
      VueCookies.remove('fanso-creator-token', '/')
    },
    setUser(state, user) {
      state.user = user
    },
    setReferer(state, referer) {
      state.referer = referer
    },
    setUtmMedium(state, utmMedium) {
      state.utmMedium =utmMedium
    },
    setQueryToken(state, queryToken) {
      state.queryToken = queryToken !== 'false'
    },
    setFromFanso(state, value) {
      state.fromFanso = value
    },
    setAuthCallback(state, authCallback) {
      state.authCallback = authCallback
    }
  },
  actions: {
    async login({ commit }, payload) {
      try {
        const response = await h365PlatformApi.post('/user/login', payload)
        if (response.isSuccess) commit('setToken', response.data)

        return response
      }
      catch (e) {
        this._vm.$modal.open('error', { content: i18n.t('action.exception.login') })
        throw e
      }
    },
    async signup({ commit }, payload) {
      try {
        const response = await h365PlatformApi.post('/user/signup', payload)
        if (response.isSuccess) commit('setToken', response.data)

        return response
      }
      catch (e) {
        this._vm.$modal.open('error', { content: i18n.t('action.exception.signup') })
        throw e
      }
    },
    async guestLogin({ commit, dispatch }) {
      try {
        const payload = {
          merchantId: router.currentRoute.query.merchantId || `${process.env.VUE_APP_MERCHANT_ID}`,
          serviceId: router.currentRoute.query.serviceId || `${process.env.VUE_APP_SERVICE_ID}`,
          clientIp: this.state.clientIp,
          ssaid: router.currentRoute.query.ssaid || ''
        }
        const response = await h365PlatformApi.post('/user/guest-login', payload)
        if (response.isSuccess) {
          commit('setToken', response.data)
          dispatch('getUser')
        }

        return response
      }
      catch (e) {
        this._vm.$modal.open('error', { content: i18n.t('action.exception.guestLogin') })
        throw e
      }
    },
    async getUser({ commit }) {
      try {
        const response = await h365PlatformApi.get('/user')
        if (response.isSuccess) commit('setUser', response.data)

        return response
      }
      catch (e) {
        this._vm.$modal.open('error', { content: i18n.t('action.exception.getUser') })
        throw e
      }
    },
    async logout(context) {
      try {
        if (context.state.token) await h365PlatformApi.post('/user/logout')
      }
      catch (e) {
        console.log('logout error')
      }
      finally {
        context.commit('removeToken')
        context.commit('setUser', null)
        this._vm.$girlModal.open('success', {
          content: i18n.t('logoutSuccess'),
          buttonText: 'OK',
        })
        router.replace({ name: 'homePage' })
      }
    },
    async changeEmail(store, payload) {
      try {
        const response = await h365PlatformApi.post('/user/changeEmail/send', payload)

        return response
      }
      catch (e) {
        this._vm.$modal.open('error', { content: i18n.t('action.exception.changeEmail') })
        throw e
      }
    },
    async changeEmailReset(store, payload) {
      try {
        const response = await h365PlatformApi.post('/user/changeEmail/reset', payload)

        return response
      }
      catch (e) {
        this._vm.$modal.open('error', { content: i18n.t('action.exception.changeEmailReset') })
        throw e
      }
    },
    goPaymentMicroservice({ state }) {
      if (!state.token) {
        this._vm.$modal.open('info', {
          content: i18n.t('goTopupMissToken'),
          buttonText: i18n.t('confirm'),
          onClick: () => {
            router.push({ name: 'homePage' })
          }
        })
        return false
      }
      const paymentMicroservice = process.env.VUE_APP_PAYMENT_MICRO_SERVICE_URL
      const query = {
        token: state.token,
        url_home: window.location.href,
        language: i18n.locale
      }
      const utmMedium = state.utmMedium || 'player_portal'
      if (utmMedium) query.utm_medium = utmMedium
      const queryString = new URLSearchParams(query)
      const topupURL = `${paymentMicroservice}?${queryString.toString()}`
      urlRedirect(topupURL)
    },
    async getPlayerPotalURL() {
      const locale = i18n.locale === 'zh-CN' ? '' : `/${i18n.locale}`
      // develop
      if (process.env.NODE_ENV !== 'production')
        return process.env.VUE_APP_PLAYER_PORTAL_URL + locale
      const domain = getDomain()
      const rootDomain = getRootDomain()
      const [subdomain] = domain.split('.')
      // sandbox
      if (subdomain.slice(0, 1) === 's')
        return `https://sswww.h365.games${locale}`
      // production
      if (rootDomain === 'h365.games')
        return `https://www.h365.games${locale}`
      try {
        const path = encodeURIComponent('public/json/url-config.json')
        const cloudStorageUrl = 'storage/v1/b/h365-landing-page/o/' + path
        const { data: { PLAYER_PORTAL_URL: cnDomain }} = await cloudStorage.get(cloudStorageUrl)

        if (cnDomain)
          return `https://${cnDomain}${locale}`
        else
          return process.env.VUE_APP_PLAYER_PORTAL_URL + locale
      }
      catch(e) {
        console.error(e)
        return process.env.VUE_APP_PLAYER_PORTAL_URL + locale
      }
    },
    async getEmailVerify(state, payload) {
      const response = await h365PlatformApi.post('/user/verifyEmail/verify', payload)
      return response
    },
    async verifyEmail(store, payload) {
      const response = await h365PlatformApi.post('/user/verifyEmail/send', payload)
      return response
    },
    async getCoinTransactionList(store, payload) {
      try {
        return await h365PlatformApi.post('/coin/transaction/list', payload)
      }
      catch (e) {
        this._vm.$modal.open('error', { content: i18n.t('action.exception.getCoinTransactionList') })
        throw e
      }
    },
    async getCoinOrderDetail(store, id) {
      try {
        return await h365PlatformApi.get(`/coin/order/detail/${id}`)
      }
      catch (e) {
        this._vm.$modal.open('error', { content: i18n.t('action.exception.getCoinTransactionDetail') })
        throw e
      }
    },
    async getCoinSpendingDetail(store, id) {
      try {
        return await h365PlatformApi.get(`/coin/spending/detail/${id}`)
      }
      catch (e) {
        this._vm.$modal.open('error', { content: i18n.t('action.exception.getCoinTransactionDetail') })
        throw e
      }
    },
    async fansoCreatorLogin({ commit }, payload) {
      try {
        const response = await fansoApi.post('/auth/performers/login', payload)
        if (response.status === 200 && response.data?.status === 0) commit('setFansoCreatorToken', response.data?.data)
        return response
      }
      catch (e) {
        this._vm.$modal.open('error', { content: i18n.t(`action.exception.fansoCreatorLogin.${e?.response?.status || '-99'}`) })
        throw e
      }
    },
    async removeFansoAuth({ commit }) {
      commit('removeFansoCreatorToken')
      this._vm.$girlModal.open('success', {
        content: i18n.t('logoutSuccess'),
        buttonText: 'OK',
      })
      router.replace({ name: 'homePage' })
    },
    async getFansoCreator({ commit }) {
      try {
        const response = await fansoApi.get('/users/me')
        if (response.status === 200 && response.data?.status === 0) return response.data?.data
        throw new Error('cant get fanso creator data')
      }
      catch (err) {
        // 清除fanso token
        commit('removeFansoCreatorToken')
        if (err?.response?.status === 403) this._vm.$modal.open('error', { content: i18n.t('action.exception.fansoCreatorTokenExpired') })
        else this._vm.$modal.open('error', { content: i18n.t('action.exception.getFansoCreator') })
        router.push({ name: 'homePage' })
      }
    },
    goToFanso({ state, username = 'home' }) {
      const creatorToken = VueCookies.get('fanso-creator-token')
      const tokenQueryString = creatorToken ? `cToken=${creatorToken}` : `token=${state.token}`
      const path = creatorToken ? username : 'home'
      window.location = `${process.env.VUE_APP_FANSO_F2E_URL}/${i18n.locale}/api/auth?${tokenQueryString}&redirectUrl=/${i18n.locale}/${path}`
    },
    handleAuthCallback({state}) {
      const token = state?.token
      if (!token) return
      let href = state.authCallback
      // 處理token問題，先跳轉到/auth路由
      const urlObj = new URL(href)
      const redirectUrl = urlObj.toString()
      const authURL = new URL('/auth', urlObj.origin)
      authURL.searchParams.set('token', token)
      authURL.searchParams.set('redirectUrl', redirectUrl)
      href = authURL.toString()
      window.location = href
    }
  },
  modules: {
  }
})
