<template>
  <div id="app">
<!--    <h-header/>-->
    <h-layout>
      <router-view/>
    </h-layout>
    <!-- footer -->
  </div>
</template>
<script>
import HLayout from '@/components/layout/HLayout.vue'
// import HHeader from '@/components/layout/HHeader.vue'
import { getDomain, handleRedirectReferer } from '@/utils'

export default {
  name: 'RootPage',
  components: { HLayout },
  async created() {
    // if token exists then fetch user info by token
    if (this.$store.state.token) {
      const res = await this.$store.dispatch('getUser')
      if (res.code === 0 && this.$store.state.referer) handleRedirectReferer(this.$store.state.referer, this.$store.state.token, this.$store.state.queryToken)
    }
  },
  mounted() {
    const domain = getDomain()
    const [subdomain] = domain.split('.')
    const navbar = document.createElement('script')
    // sandbox & production filename
    const filename = subdomain.slice(0, 1) === 's' || process.env.NODE_ENV !== 'production'
      ? 'navbar-test.js'
      : 'navbar.js'
    navbar.src = `${process.env.VUE_APP_CLOUD_SOTRAGE_URL}/h365-landing-page/public/js/${filename}`
    navbar.src += `?env=${process.env.NODE_ENV}`
    navbar.src += '&source=account'

    document.body.insertAdjacentElement('beforeend', navbar)
  }
}
</script>
